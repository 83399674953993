<template>
  <b-row class="settings">
    <b-col>
      <PageHeader :title="$t('general.settingsPages.newPassword.title')" />

      <b-row>
        <div class="settings__form">
          <div class="settings__input">
            <CInput
              type="password"
              name="old_password"
              :custom-field-name="
                $t(
                  'general.settingsPages.newPassword.placeholder.currentPassword'
                )
              "
              theme="settings"
              class="settings__field"
              v-model="oldPassword"
              :label="
                $t(
                  'general.settingsPages.newPassword.placeholder.currentPassword'
                )
              "
              :rules="'required|min:8'"
            >
              <template #appendAddition>
                <span class="settings__counter">
                  <CLink
                    to="/forgot"
                    class="settings__font settings__font--link"
                  >
                    {{ $t("general.settingsPages.newPassword.forgot") }}
                  </CLink>
                </span>
              </template>
            </CInput>
          </div>

          <hr class="settings__hr" />

          <div class="settings__input">
            <CInput
              type="password"
              name="new_password"
              :custom-field-name="
                $t('general.settingsPages.newPassword.placeholder.newPassword')
              "
              theme="settings"
              class="settings__field"
              v-model="newPassword"
              :label="
                $t('general.settingsPages.newPassword.placeholder.newPassword')
              "
              :rules="'required|min:8'"
            >
              <template #appendAddition>
                <span class="settings__counter">
                  <span class="settings__font settings__font--addition">
                    {{ $t("general.settingsPages.newPassword.additionText") }}
                  </span>
                </span>
              </template>
            </CInput>
          </div>
          <div class="settings__input">
            <CInput
              type="password"
              name="new_password_confirmation"
              :custom-field-name="
                $t(
                  'general.settingsPages.newPassword.placeholder.confirmPassword'
                )
              "
              theme="settings"
              class="settings__field"
              v-model="newPasswordConfirm"
              :label="
                $t(
                  'general.settingsPages.newPassword.placeholder.confirmPassword'
                )
              "
              :rules="{
                required: Boolean(newPassword),
                is: newPassword,
              }"
            >
              <template #appendAddition>
                <span class="settings__counter">
                  <span class="settings__font settings__font--addition">
                    {{ $t("general.settingsPages.newPassword.additionText") }}
                  </span>
                </span>
              </template>
            </CInput>
          </div>
        </div>

        <div class="settings__button-wrapper">
          <button
            type="button"
            @click.prevent="saveSettings"
            class="base-button settings__button"
          >
            <span class="base-button__font">
              {{ $t("general.privacyPage.button.update") }}
            </span>
          </button>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import PageHeader from "@/layout/PageHeader.vue";
import CInput from "@/features/ui/СInput.vue";
import CLink from "@/features/ui/CLink.vue";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
export default {
  name: "PagePassword",
  mixins: [waitRequest, validationError],
  components: {
    CLink,
    CInput,
    PageHeader,
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    };
  },

  methods: {
    async saveSettings() {
      const isValid = await this.$validator.validateAll();

      if (!isValid || this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$post(
          "/profile/password",
          {
            old_password: this.oldPassword,
            new_password: this.newPassword,
            new_password_confirmation: this.newPasswordConfirm,
          },
          () => {
            this.$bvToast.toast(this.$t("general.settings-saved"), {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-bottom-left flow-toast",
            });
          },
          (errors) => {
            console.log(errors);
          },
          this.checkErrors
        );
      }).catch(this.checkErrors);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  &__hr {
    width: em(233);
    height: 1px;
    background: #f6f6f6;
    border: none;
    margin-top: em(17);
    margin-bottom: em(31);
  }

  &__form {
    margin-top: em(20);
    width: 100%;
    margin-right: em(16);
    margin-left: em(16);
  }

  &__button {
    margin-top: em(17);
    margin-right: em(15);
    margin-left: auto;
  }

  &__button-wrapper {
    display: flex;
    width: 100%;
    margin-top: em(19);
    border-top: 1px solid $app-gray-2;
  }

  &__input {
    margin-top: em(18);
    position: relative;
  }

  &__counter {
    position: absolute;
    top: 100%;
    right: em(0);
  }

  &__field {
  }

  &__warning {
  }

  &__font {
    &--warning {
      font-family: $font-default;
      font-size: em(14);
      color: $app-red;
    }

    &--addition {
      font-family: $font-default;
      font-size: em(12);
      color: $app-font-color-placeholder;
    }

    &--link {
      font-family: $font-default;
      font-size: em(12);
    }
  }

  &__icon {
    color: $app-gray-18;

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
</style>
