var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"settings"},[_c('b-col',[_c('PageHeader',{attrs:{"title":_vm.$t('general.settingsPages.newPassword.title')}}),_vm._v(" "),_c('b-row',[_c('div',{staticClass:"settings__form"},[_c('div',{staticClass:"settings__input"},[_c('CInput',{staticClass:"settings__field",attrs:{"type":"password","name":"old_password","custom-field-name":_vm.$t(
                'general.settingsPages.newPassword.placeholder.currentPassword'
              ),"theme":"settings","label":_vm.$t(
                'general.settingsPages.newPassword.placeholder.currentPassword'
              ),"rules":'required|min:8'},scopedSlots:_vm._u([{key:"appendAddition",fn:function(){return [_c('span',{staticClass:"settings__counter"},[_c('CLink',{staticClass:"settings__font settings__font--link",attrs:{"to":"/forgot"}},[_vm._v("\n                  "+_vm._s(_vm.$t("general.settingsPages.newPassword.forgot"))+"\n                ")])],1)]},proxy:true}]),model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1),_vm._v(" "),_c('hr',{staticClass:"settings__hr"}),_vm._v(" "),_c('div',{staticClass:"settings__input"},[_c('CInput',{staticClass:"settings__field",attrs:{"type":"password","name":"new_password","custom-field-name":_vm.$t('general.settingsPages.newPassword.placeholder.newPassword'),"theme":"settings","label":_vm.$t('general.settingsPages.newPassword.placeholder.newPassword'),"rules":'required|min:8'},scopedSlots:_vm._u([{key:"appendAddition",fn:function(){return [_c('span',{staticClass:"settings__counter"},[_c('span',{staticClass:"settings__font settings__font--addition"},[_vm._v("\n                  "+_vm._s(_vm.$t("general.settingsPages.newPassword.additionText"))+"\n                ")])])]},proxy:true}]),model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_vm._v(" "),_c('div',{staticClass:"settings__input"},[_c('CInput',{staticClass:"settings__field",attrs:{"type":"password","name":"new_password_confirmation","custom-field-name":_vm.$t(
                'general.settingsPages.newPassword.placeholder.confirmPassword'
              ),"theme":"settings","label":_vm.$t(
                'general.settingsPages.newPassword.placeholder.confirmPassword'
              ),"rules":{
              required: Boolean(_vm.newPassword),
              is: _vm.newPassword,
            }},scopedSlots:_vm._u([{key:"appendAddition",fn:function(){return [_c('span',{staticClass:"settings__counter"},[_c('span',{staticClass:"settings__font settings__font--addition"},[_vm._v("\n                  "+_vm._s(_vm.$t("general.settingsPages.newPassword.additionText"))+"\n                ")])])]},proxy:true}]),model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}})],1)]),_vm._v(" "),_c('div',{staticClass:"settings__button-wrapper"},[_c('button',{staticClass:"base-button settings__button",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.saveSettings.apply(null, arguments)}}},[_c('span',{staticClass:"base-button__font"},[_vm._v("\n            "+_vm._s(_vm.$t("general.privacyPage.button.update"))+"\n          ")])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }